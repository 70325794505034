import * as React from "react"
import { Link, graphql } from "gatsby"
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import {GatsbyImage} from 'gatsby-plugin-image'
import { ThemeProvider} from '@material-ui/core/styles'
import {createTheme} from '@mui/system'
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";


import Layout from "../components/layout"
import Seo from "../components/seo"




const ContactForm = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title 
    const reducer = (currentState, nextState) => ({...currentState, ...nextState})
    
    const initialValues = {
      name: "",
      email: "",
      message: "",
    }

    const initialErrors = {
      name: false,
      email: false,
      message: false,
    }

    const [values, setValues] = React.useReducer(reducer, initialValues)
    const [errors, setErrors] = React.useReducer(reducer, initialErrors)
    function encode(data) {
        return Object.keys(data)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    }
    const handleSubmit = (event) => {

        console.log('sending')
        fetch("/contact", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
              "form-name": event.target.getAttribute("name"),
              ...values,
          }),
        })
        .then(() => console.log('message sent'))
        .catch((event) => console.log("Error: ", event));
    }
  
    const theme = createTheme({
        palette: {
            mode: 'dark',
          primary: {
            main: '#0c1021',
          },
          secondary: {
            main: '#dadbde',
          }
        }
        
      })
    const passingsx = {bgcolor: theme.palette.primary.main, color: theme.palette.secondary.main};


  return (
    <Layout location={location} title={siteTitle}>        
        <ThemeProvider theme={theme}>
        <Card >            
        <Box
        component="form"
        className="contact-form"
        name="contact"          
        method="POST"                    
        data-netlify="true"  
        sx={passingsx}
        onSubmit={handleSubmit}        
        >
            
        <input type="hidden" name="form-name" value="contact" />  
        <input className="form-field" placeholder="Name" type="text" name="name" />  
        <input className="form-field" placeholder="Email" type="text" name="email" />  
        <textarea className="form-field" rows="4" placeholder="Message" name="message"></textarea> 

           <Box
           className="submitContainer"
           >
            <Button className="btnbtn" type="submit">Send</Button>
            </Box>
            </Box>
            </Card>
            </ThemeProvider>
    </Layout>
  )
}

export default ContactForm
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`